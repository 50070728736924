<template>
  <v-btn  large outlined color="" @click="goBack()">{{text}}</v-btn>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    text: {
      default: 'Cancel',
      type: String,
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
